<template lang="">
  <!-- Modal confirm ban AG-->
  <b-modal
    :id="`ban-ag-modal`"
    size="md"
    centered
    header-bg-variant="light-info"
    hide-footer
    no-close-on-backdrop
    :title="$t('modal.confirmBan')"
  >
    <IAmOverlay :loading="loading">
      <v-select
        id="type"
        v-model="banRq.day"
        :options="banOptions"
        :reduce="e => e.value"
        :clearable="false"
        rules="required"
      >
        <template #option="data">
          <span>{{ data.label }}</span>
        </template>
        <template #selected-option="data">
          <span>{{ data.label }}</span>
        </template>
        <template #no-options>
          {{ $t('noOptions') }}
        </template>
      </v-select>

      <div class="d-flex-center gap-3 mt-1">
        <b-button
          variant="danger"
          @click="confirmBanAG"
        >
          {{ $t('resellTicket.btn.ban') }}
        </b-button>
        <b-button
          variant="secondary"
          @click="() => {
            this.$bvModal.hide('ban-ag-modal')
          }"
        >
          {{ $t('cancel') }}
        </b-button>
      </div>
    </IAmOverlay>
  </b-modal>
</template>
<script>
import { BModal, BButton } from 'bootstrap-vue'
import { onMounted, ref, toRefs } from '@vue/composition-api'

import router from '@/router'

import { convertISODateTime, formatCurrency } from '@core/utils/filter'

import useResellTicketHandle from '@saleReport/useResellTicketHandle'

export default {
  components: {
    BModal,
    BButton,
    vSelect: () => import('vue-select'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  props: {
    agId: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { agId } = toRefs(props)
    const {
      loading,
      banAGByAGId,
    } = useResellTicketHandle()
    const banRq = ref({ day: 7 })
    function confirmBanAG() {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmBan') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirmBan'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            const { day } = banRq.value
            banAGByAGId(agId.value, day).then(() => {
              emit('refresh')
              this.$bvModal.hide('ban-ag-modal')
            })
          }
        })
    }

    const banOptions = [
      { value: 7, label: '7 ngày' },
      { value: 14, label: '14 ngày' },
      { value: 30, label: '30 ngày' },
      { value: -1, label: 'Vĩnh viễn' },
    ]
    onMounted(() => {
      loading.value = false
    })
    return {
      confirmBanAG, loading, banRq, banOptions,
    }
  },
}
</script>
<style lang="">

</style>
