var render = function () {
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "ban-ag-modal",
      "size": "md",
      "centered": "",
      "header-bg-variant": "light-info",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "title": _vm.$t('modal.confirmBan')
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('v-select', {
    attrs: {
      "id": "type",
      "options": _vm.banOptions,
      "reduce": function reduce(e) {
        return e.value;
      },
      "clearable": false,
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(_vm._s(data.label))])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(_vm._s(data.label))])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.banRq.day,
      callback: function callback($$v) {
        _vm.$set(_vm.banRq, "day", $$v);
      },
      expression: "banRq.day"
    }
  }), _c('div', {
    staticClass: "d-flex-center gap-3 mt-1"
  }, [_c('b-button', {
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": _vm.confirmBanAG
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('resellTicket.btn.ban')) + " ")]), _c('b-button', {
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": function click() {
        _this.$bvModal.hide('ban-ag-modal');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }